export const UserLoginMessage = {
  LogginFailure: "Login Failed. Incorrect Email and Password.",
  LogginSuccess: "User logged in",
  LoginEmailBlank: "Enter Email Address",
  LoginPasswordBlank: "Enter Password",
  LoginInvalidEmail: "Enter valid Email",
};
export const Abstractor_Msgs = {
  FIRSTNAME: "Enter first name",
  LASTNAME: "Enter last name",
  EMAIL_REQUIRED: "Enter email address",
  EMAIL_VALID: "Please enter a valid email",
  CENTER: "Please select center",
  REQUIRED: "This field is required",
  PASSWORD_VALID:
    "Your password must contain one lowercase letter, one uppercase letter, one number, and one symbol",
  EMAIL_EXISTS: "Email address already exists",
  CENTER_LOAD_ERROR: "Error occurred while loading centers",
  ADD_SUCCESS: "Abstractor added successfully",
  EDIT_SUCCESS: "Abstractor updated successfully",
  DELETE_SUCCESS: "Abstractor deleted successfully",
  DELETE_FAIL: "Error occurred when deleting abstractor",
  EMAIL_SENDOUT_SUCCESS: "Email sent successfully", //"An email has been sent to the email address regarding the further process",
  EMAIL_SENDOUT_ERROR: "Email address does not exists",
};
export const Center_Msgs = {
  NAME: "Enter center name",
  ADD_SUCCESS: "Center added successfully",
  EDIT_SUCCESS: "Center updated successfully",
  DELETE_SUCCESS: "Center deleted successfully",
  DELETE_FAIL: "Error occurred when deleting center",
  NAME_EXISTS: "The center name already exists",
};
export const ResetPasswordMessages = {
  PASSWORD_BLANK: "Enter Password",
  CONFIRM_PASSWORD_BLANK: "Re-Enter Password",
  PASSWORD_MISMATCH: "Passwords did not match!",
  PASSWORD_INVALID:
    "Your password should include at least one lowercase letter, one uppercase letter, one number and one symbol with a minimum length of eight characters.",
  LINK_EXPIRED: "Link has expired",
  EMAIL_NOTEXISTS: "Email address does not exists",
  //100:"Some error!!! "
};
export const CommonError_Msgs = {
  LOAD_ERR: "Error occurred while loading.",
  SAVE_ERR: "Error occurred while saving.",
  REQUIRED: "This field is required.",
  DATE_BEFORE_1920: "Date entered is before 1920.",
  Hard_Empty:
    "Please ensure that all mandatory fields are filled out before proceeding.",
  UnknownSelect:
    "You have selected “Unknown” for this question. Please confirm that this is your choice before moving to the next question.",
  InvalidDate: "Date does not exist",
  DateNotValid: "Invalid Date. Please enter a valid date.",
  //Soft
  NotInRange: "You have indicated the value as [value] [value units]. Please confirm or revise your response.",
  //the user has completed the entire section, but there is an error based on their response
  IncorrectResponse: "Please confirm or revise your responses."
};
export const MessageRequired_Msg = {
  Q24: "please select any one option for treatment",
  Required: "This field is required.",
};
export const ForgotPassword = {
  EmailNotExistent:
    "This Email Address has not been set up - Please contact your Administrator to set this up.",
  EmailSuccess: "An Email has been sent to you regarding the further process.",
  ErrorOccurred: "Error occurred.",
};
export const CommonMsgs = {
  DeleteConfirmMsg: "Are you sure you want to delete this data?",
  SessionTimeout: "Your session has expired, you will be logged out now.",
  SubmitSuccess: "Patient Submitted Successfully",
  Unknown:
    "You have selected “Unknown” for this question. Please confirm that this is your choice before moving to the next question.",
};
export const PatientDashboard_Msgs = {
  ExportSuccess: "Data exported successfully",
  UnlockConfirmMsg:
    "Would you like to unlock and allow the abstractors to edit this patient?",
  UnlockSuccessMsg: "Patient Unlocked successfully",
  SyncSuccessMsg: "Patient data updated successfully",
};

export const EligibilityCriteria_Msgs = {
  Radio: "Please choose one of the provided options",
  Checkbox: "Please choose at least one of the provided options",
  q3ODtEmptyMsg: "Please enter the Obinutuzumab initiated date",
  q3VDtEmptyMsg: "Please enter the Venetoclax initiated date",
  q4TEmptyMsg: "Please choose at least one of the therapies options",
  q4IDtEmptyMsg: "Please enter the initiated date",
  q4SDtEmptyMsg: "Please enter the stop date",
  q4OthTxtEmptyMsg: "Please enter the other text value",
  q7DtEmptyMsg: "Please enter date of completion of venetoclax ramp-up",
  q9AllSDtEmpty: "Please enter the start date of allopurinol (if known) or “Unknown” otherwise",
  q9AllEDtEmpty: "Please enter the end date of allopurinol (if known) or “Unknown” otherwise",
  q9AHSDtEmpty: "Please enter the start date of antihyperuricemics (if known) or “Unknown” otherwise",
  q9AHEDtEmpty: "Please enter the end date of antihyperuricemics (if known) or “Unknown” otherwise",

  q3TerminateIneligible: "The patient must have initiated VO therapy as a first-line or second line of therapy. Please confirm or revise your response.",
  q4TerminateIneligible: "To continue with data abstraction, the patient’s targeted therapy information must be known. Please confirm or revise your response.",
  q5TerminateIneligible: "The patient must have been treated with VO therapy outside of a clinical trial. Please revise your response.",
  q6TerminateIneligible: "The patient must not have received any other agent in addition to venetoclax or obinutuzumab as part of their VO therapy. Please revise your response.",
  q7TerminateIneligible: "The patient’s date of completion of venetoclax ramp-up must be known. Please revise your response.",
  q8TerminateIneligible: "It must be indicated whether the venetoclax ramp-up period was followed as per-label. Please revise your response.",
  q9TerminateIneligible: "The patient must have received antihyperuricemics prior to the initiation of venetoclax, as per label. Please revise your response.",
  q10TerminateIneligible: "The patient must not have received any strong CYP3A inhibitors or rasburicase during the 3 days prior to the initiation of venetoclax on [Venetoclax Start Date]. Please revise your response.",
  q10TerminateIneligibleUnk: "It must be known whether the patient received any strong CYP3A inhibitors or rasburicase during the 3 days prior to the initiation of venetoclax on [Venetoclax Start Date]. Please revise your response.",

  q3IndxLTDiagMsg: "The date of initiation of first line therapy must be on or after the patient’s diagnosis date on [Diagnosis Date]. Please confirm or revise your response.",
  q3IndxLTMidMayMsg: "The patient’s date of initiation of VO therapy must be on or after May 15, 2019. Please confirm or revise your response.",
  q3VenLTIndxMsg: "The patient’s date of initiation of obinutuzumab therapy must be on or before the initiation of venetoclax therapy. Please confirm or revise your response.",
  q3IndxLTVenMTTMsg: "You have indicated that the patient received obinutuzumab therapy over 22 days prior to the initiation of venetoclax therapy. Please confirm or revise your response.",
  q4SSDtGT30Msg: "If VO was received as a second-line therapy, the total duration of the first-line targeted therapy should be less than 30 days. Please confirm or revise your response.",
  q7VSEDtLT35Msg: "You have indicated that venetoclax ramp-up was less than 5 weeks (i.e., between [Ramp-up Period]). Please confirm or revise your response.",
  q9SDtLTEDtMsg: "The start date of [Medication received] must be before the end date of the [Medication received] received. Please revise your response.",
  q9SDtGTVSDtMsg: "The start date of [Medication received] must be before the initiation of venetoclax on [Venetoclax Start Date]. Please revise your response.",
  q9SDtLTVEDtMsg: "You have indicated that the patient stopped [Medication received] prior to the end of the venetoclax ramp-up end date on [Venetoclax End Date]. Please revise your response.",
  q9SDtLTDobMsg: "The start date of [Medication received] must be after the patient’s date of birth on [Date of BirthDiagnosis Date]. Please revise your response.",

  EligiblePatient:
    "This patient chart meets the study eligibility criteria. Please proceed to the next section of the chart abstraction form.",
  IneligiblePatient:
    "Thank you for entering the information, however the selected patient chart is not eligible for this study. No further information for this patient will be collected. Click on navigation button to proceed with entering a new patient chart.",
  INELIGIBLE:
    "This patient does not meet the eligibility criteria. Please confirm or revise your responses.",

  //PreScreening Error Messages
  radioEmpty: "Please choose at least one of the provided options or “Unknown”",
  textEmpty: "Please enter values",
  alcInvalid: "Please enter valid Alc value",
  ineligiblePatient: "Thank you for entering the information, however the selected patient chart is not eligible for this study. No further information for this patient will be collected. Click on next arrow to proceed with entering a new patient chart.",
  terminate: "The patient must have low or medium tumor burden with CrCl of >= 80 ml/min to be eligible for this study. Please confirm or revise your response.",
  terminate_other: "The patient must have low or medium tumor burden with CrCl of >= 80 ml/min to be eligible for this study. Please confirm or revise your response.",
  crclInvalid: "Please enter a CrCl value between 80 and 120",
  rangeInvalidErrorMsgStart: "You have indicated the value as ",
  rangeInvalidErrorMsgEnd: "Please confirm or revise your response.",

  //Invalid user entered date Error Message
  invalidDate:"Invalid Date. Please enter a valid ",
}


export const Demographics_Msgs = {
  gender: "Please choose one of the provided options",
  race: "Please choose at least one of the provided options or “Other” or “Unknown”",
  ethnicity: "Please choose one of the provided options",
}

export const RelevantBloodTests = `
  <div>
    <table style="border-collapse: collapse; width: 50%; text-align: left; margin: 0 auto;>
      <thead style="background-color: #D0CECE;">
        <tr style="background-color: #D0CECE;">
          <th style="border: 1px solid #000; padding: 8px; font-weight: bold;">Relevant blood tests</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Absolute lymphocyte count (ALC)</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Calcium</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Serum creatinine</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Serum lactate dehydrogenase (LDH)</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Phosphorus</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Potassium</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Uric acid</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 8px;">Creatinine clearance level (CrCl)</td>
        </tr>
      </tbody>
    </table>
    <br/>
  </div>
`;



export const ClinicalCharacteristics_Msgs = {
  weightRequired: "Please select patient's weight.",
  q18WeightRange: "You have indicated the value as [value] [value units]. Please confirm or revise your response.",
  q18WeightRangeEmpty: "Please enter patient's weight. ",
};


export const TLS_Table = `
  <div>
            <p class="modal-popup-title">
          <b>Howard Definitions of Laboratory and Clinical Tumor Lysis Syndrome (TLS)*</b>
        </p>
        <table class="modal-popup-table-large" style="width:100%">
          <thead class="modal-popup-table-header">
            <tr>
              <th class="modal-popup-table-header-cell">Metabolic Abnormality</th>
              <th class="modal-popup-table-header-cell">Criteria for Classification of Laboratory TLS</th>
              <th class="modal-popup-table-header-cell">Criteria for Classification of Clinical TLS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="modal-popup-table-cell">Hyperuricemia</td>
              <td class="modal-popup-table-cell">Uric acid &gt;8.0 mg/dL (475.8 μmol/L) in adults or above the upper limit of the normal range for age in children</td>
              <td class="modal-popup-table-cell"></td>
            </tr>
            <tr>
              <td class="modal-popup-table-cell">Hyperphosphatemia</td>
              <td class="modal-popup-table-cell">Phosphorus &gt;4.5 mg/dL (1.5 mmol/L) in adults or &gt;6.5 mg/dL (2.1 mmol/L) in children</td>
              <td class="modal-popup-table-cell"></td>
            </tr>
            <tr>
              <td class="modal-popup-table-cell">Hyperkalemia</td>
              <td class="modal-popup-table-cell">Potassium &gt;6.0 mmol/L</td>
              <td class="modal-popup-table-cell">Cardiac dysrhythmia or sudden death probably or definitely caused by hyperkalemia</td>
            </tr>
            <tr>
              <td class="modal-popup-table-cell">Hypocalcemia</td>
              <td class="modal-popup-table-cell">Corrected calcium &lt;7.0 mg/dL (1.75 mmol/L) or ionized calcium &lt;1.12 (0.3 mmol/L)†</td>
              <td class="modal-popup-table-cell">Cardiac dysrhythmia, sudden death, seizure, neuromuscular irritability (tetany, paresthesias, muscle twitching, carpopedal spasm, Trousseau’s sign, Chvostek’s sign, laryngospasm, or bronchospasm), hypotension, or heart failure probably or definitely caused by hypocalcemia</td>
            </tr>
            <tr>
              <td class="modal-popup-table-cell">Acute kidney injury‡</td>
              <td class="modal-popup-table-cell">Not applicable</td>
              <td class="modal-popup-table-cell">Increase in the serum creatinine level of 0.3 mg/dL (26.5 μmol/L) (or a single value &gt;1.5 times the upper limit of the age-appropriate normal range if no baseline creatinine measurement is available) or the presence of oliguria, defined as an average urine output of &lt;0.5 mL/kg/hr for 6 hours</td>
            </tr>
          </tbody>
        </table>
        <p class="modal-para">
          *In laboratory TLS, two or more metabolic abnormalities must be present during the same 24-hour period within 3 days before the start of therapy or up to 7 days afterward. Clinical TLS requires the presence of laboratory TLS plus an increased creatinine level, seizures, cardiac dysrhythmia, or death.
        </p>
        <p class="modal-para">
          †The corrected calcium level in milligrams per deciliter = measured calcium level in milligrams per deciliter + 0.8 × (4 − albumin in grams per deciliter).
        </p>
        <p class="modal-para">
          ‡Acute kidney injury is defined as an increase in the creatinine level of at least 0.3 mg per deciliter (26.5 μmol per liter) or a period of oliguria lasting 6 hours or more. By definition, if acute kidney injury is present, the patient has clinical TLS.
        </p>
  </div>
`;


export const HealthCareVisit_Msgs = {
  healthCoverage: "Please select patient's healthcare coverage.",
  healthCoverageOther: "Please specify value for other healthcare coverage.",
  insurance: "Please select patient's insurance.",
  hruInOutOBVeneto: "Please specify healthcare visits between initiation of obinutuzumab and initiation of venetoclax ramp-up.",
  hruInOutOBVenetoValue: "Please enter healthcare visits.",
  hruInOutOBVenetoInvalidRange: "You have indicated the value as [value] [value units]. Please confirm or revise your response.",
  hruInOutInitVeneto: "Please specify healthcare visits between initiation of obinutuzumab and initiation of venetoclax ramp-up.",
  hruInOutInitVenetoValue: "Please enter healthcare visits.",
  hruInitVenetoInvalidRange: "You have indicated the value as [value] [value units]. Please confirm or revise your response.",

  //common Loop Messages
  categoryEmpty: "Please select patient's healthcare coverage.",
  reasonsEmpty: "Please specify a reason for visit.",
  hruVisitDateEmpty: "Please select date of healthcare visit.",
  hruVisitDateValueEmpty: "Please specify the visit date.",
  hruTreatmmentRestartDaysEmpty: "Please specify days.",
  hruTreatmmentRestartEmpty: "Please specify how many days after the visit the treatment was restarted for visit.",
  venetoInitRamUpInvalidDate: "The patient’s healthcare visits (inpatient or outpatient) date(s) must be on or after the initiation of obinutuzumab on [insert Index Date] and before the initiation of venetoclax ramp-up on [insert Venetoclax Start Date]. Please confirm or revise your response.",
  venetoRestartedInvalidDays: "You have indicated the patient restarted treatment after the initiation of venetoclax therapy on [insert Venetoclax Start Date]. Please confirm or revise your response.",
  venetoInitRamUpCompleteInvalidDate: "The patient’s healthcare visits (inpatient or outpatient) date(s) must be on or after the initiation of venetoclax therapy on [insert Venetoclax Start Date] and on or before the end of venetoclax ramp-up on [insert Venetoclax End Date]. Please confirm or revise your response.",
  venetoRestartedCompleteInvalidDays: "You have indicated the patient restarted treatment after the venetoclax ramp-up ended on [insert Venetoclax End Date]. Please confirm or revise your response.",
  invalidDate:"Invalid Date. Please enter a valid date.",
};

export const TreatmentInterruption_Msgs = {
  total_No_interruptionsEmpty: "Please Select Yes , No or Unknown",
  total_No_interruptions_txtEmpty: " Please enter interruptions/dose holds or select Unknown",
  total_No_interruptions_txt_valueEmpty: "Please enter interruptions/dose holds value",
  interruption_DateEmpty: "Please enter date of treatment interruption/dose hold or select Unknown",
  interruption_Date_ValueEmpty: "Please enter date of treatment interruption/dose hold",
  veneto_Restarted_DateEmpty: "Please enter date in which the venetoclax ramp-up was restarted or select Not applicable or Unknown",
  veneto_Restarted_Date_ValueEmpty: "Please enter date in which the venetoclax ramp-up was restarted",
  interruptoinsNoOrUnkErrorMsg: "You previously indicated that a treatment interruption/dose hold was used as an intervention following a prior lab result. Please confirm your response.",
  rangeInvalidErrorMsgStart: "You have indicated the value as ",
  rangeInvalidErrorMsgEnd: " interruptions/dose holds. Please confirm or revise your response.",
  invalidDate:"Invalid Date. Please enter a valid date.",
}
export const VenRampUp_Msgs = {
  doseEmpty: "Please choose one of the provided options",
  weeksEmpty: "Please enter the weeks",
  agentEmpty: "Please select the uric acid reduction agents",
  agentTxtEmpty: "Please enter the text",
  //Rpt
  rcvdDateEmpty:"Please enter the date when the first venetoclax ramp-up dose was received.",

  HrsEmpty:"Please enter the time(hours).",
  MinsEmpty:"Please enter the time(mins).",
  HrsMinsEmpty:"Please enter the time(hours) and time(mins).",

  wk1DoseDate: "You indicated the patient initiated venetoclax on {venStartDt}, but started initiated their ramp-up of venetoclax on {doseDt}. Please confirm or revise your response.",
  doseDtLessThanPrior: "The date of venetoclax ramp-up in week {currWk} must be after the date of venetoclax ramp-up in week {prevWk}. Please confirm or update your response.",
  Radio: "Please choose one of the provided options",
  medTxtEmpty: "Please enter the text",
  strongCyp3a: "You have indicated that the patient received a strong CYP3A inhibitors which would deem the patient ineligible for the study. Please confirm or revise your response.",
  doseReceivedTxtEmpty: "Please enter the dose",
  adminTxtEmpty: "Please enter the text",
  Select:"Please choose at least one of the options provided.",
}

export const TLSMonitoring_Msgs = {
  Select:"Please choose at least one of the options provided.",
  TextEmpty:"Please enter the value",
  Total_No_interruptionsEmpty: "Please choose at least one of the provided options",
  InvalidRange:"You have indicated the value as ",
  InvalidRangeEmptyRemining :" radiographic evaluations. Please confirm or revise your response.",
  TestCompleteTxtEmptyMsg: "You have indicated the value as [value] [value units]. Please confirm or revise your response.",
  BloodTestCountEmpty: "Please specify blood test count.",
  BloodTestCompleteCountEmptyMsg: "You have indicated the value as [value] [value units]. Please confirm or revise your response.",
  testperformed_dtSoft: "This test was completed more than 2 months before initiation of VO therapy or after the initiation of venetoclax (i.e, outside of {Index date – 2 months} and {Venetoclax Start Date}). Please confirm or revise your response.",
  lymphnodesizeSoft:"You have indicated the value as {value} cm. Please confirm or revise your response.",
  lymphNodegreaterthanfiveTxtSoft:"You have indicated the value as {value} lymph nodes. Please confirm or revise your response.",
  testperformedDateEmpty:"Please enter the date when the test was performed.",
  HrsEmpty:"Please enter the time(hours).",
  MinsEmpty:"Please enter the time(mins).",
  sizeTxtEmpty:"Please enter size of largest lymph node.",
  sizeTxtymphNodegreaterthanfiveTxt:"Please enter identified number of lymph node greater than 5cm.",
  HrsMinsEmpty:"Please enter the time(hours) and time(mins).",

  Radio: "Please choose one of the provided options",
  q36T1ADateEmptyMsg: "Please enter date of blood draw",
  q36T1ADateCheckErrorMsg: "The date of the [N] blood draw must be after the date of the [N-1] blood draw. Please confirm or update your response.",
  q36T1ADateVenCheckErrorMSg: "The lab test must have been completed during the venetoclax ramp-up period. Please confirm or update your response.",

  q36T1BSubValueEmptyMsg: "Please choose one of the sub options provided",
  q36T1CTimeEmptyMsg: "Please enter other time of blood draw",
  q36T1CHrsEmptyMsg: "Please enter hours",
  q36T1CMinsEmptyMsg: "Please enter mins",
  q36T1FOtherTxtEmptyMsg: "Please enter the other text value",
  testresultSoft:"You have indicated the value as {value} mL/min. Please confirm or revise your response.",
  alc_datetestperformed_dtEmptyMsg:"Please enter the date when the test was performed.",
  crcl_datetestperformed_dtEmptyMsg:"Please enter the date when the test was performed.",
  alc_testresultTxtEmptyMsg:"Please enter test result.",
  crcl_testresultTxtEmptyMsg:"Please enter test result.",

  q36T1FChildEmptyMsg: "Please choose one of the sub options provided",
  q36T2DResultEmptyMsg: "Please enter the test result value",
  q36T2COthTxtEmptyMsg: "Please enter the other formula type value",
  q36T2DAllUnknownEmptyMsg: "You have indicated that the test results are unknown for all of the specified blood tests completed. Please review your response. Please note that as part of patient eligibility you must have complete information for each patient.",
  q36T2BAllUnkNotTestedEmptyMsg: "You have indicated that none of the specified blood tests were completed. Please review your response. Please note that as part of patient eligibility you must have complete information for each patient.",
  q36T1ColBCSoftMsg: "You previously indicated a prior lab was used for pre-dose TLS assessment as per label. Please confirm whether this was a later TLS assessment or a repeat lab within the same time frame.",
}

export const GetPatientModal_Msgs = {
  invalidPatient: "Please enter valid patient ID and select a patient."
}