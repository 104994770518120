import React, { useState } from "react";
import { useNavigate } from "react-router";
import { AddLocalStorageData, GetLocalStorageData } from "../Helpers/Utilities";
import {useParams } from 'react-router-dom';
const SaveAndNavigate = (props) => {
  const navigate = useNavigate();
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  let patientData = GetLocalStorageData("patient")
  patientData = patientData && JSON.parse(patientData);
  const { lot } = useParams();
  
  const locked = patientData?.locked ?? false;
  //const isEligible = props?.isEligible??true;



  //Validate and save
  const HandlePreviousClick = async () => {
    // if(props.paths.currPage === "/PreScreening" && await props.Save(true)){
    //   navigate(props.paths.prevPage);
    // }
    
    if (props.paths.prevPage === "/EligibilityCriteria"  && await props.Save(true) ) {
      if(!locked)
        await props.Save(true);
      navigate(props.paths.prevPage);
    }
    
    else
      if (locked || await props.Save(true)) {
        navigate(props.paths.prevPage);
      }
    window.scrollTo(0, 0);
    //LoadConfig()
  }
  //Validate and save
  const HandleNextClick = async () => {
    if (locked || await props.Save(true)) {
      navigate(props.paths.nextPage);
    }

    //ST: 07/10/2024, Commented because validtion is there on both pages, should not allow next or prev if harderror msg is shown
    // else if (props.paths.currPage === "/EligibilityCriteria"){
    //   navigate(props.paths.nextPage);
    // }
    // else if (props.paths.currPage === "/PreScreening"){
    //     navigate(props.paths.nextPage);
    // }
    window.scrollTo(0, 0);
    //LoadConfig()
  };
  //save without validating
  const HandleSaveClick = async () => {
    locked || await props.Save(false)
    window.scrollTo(0, 0);
    LoadConfig()
  }
  //Validate and save
  const HandleSubmitClick = async () => {
    locked || await props.Save(true)
    window.scrollTo(0, 0);
    LoadConfig()
  }
  const LoadConfig = () => {
    AddLocalStorageData("loadConfig", true)
  }

  return (
    <>
      {props.paths.prevPage &&  (
        <a onClick={HandlePreviousClick}>
          <img
            className="previous-btn-padding"
            src="../Assets/images/previous.png"
            alt=""
          />
        </a>
      )}

      {/* ST: // 06/11/2024: Commented Save button since not required by client https://ideawharf.sifterapp.com/issues/12780 */}
      {/* {
       props.paths.currPage !== "/EligibilityCriteria" &&
       props.paths.currPage !== "/Introduction" 

       //ST: 28/09/2024: Commneted below line since prescreening has partial saving
      //  &&
      //  props.paths.currPage !== "/PreScreening"  
       && (
        <a
          className="btn save-btn"
          onClick={HandleSaveClick}
          style={{ fontWeight: "600", fontSize: "large", marginRight: "20px" }}
        >
          Save
        </a>
      )} */}

      {/* } */}
      {(
        // props.paths.currPage !== "/EligibilityCriteria" &&
        props.paths.currPage !== "/Introduction" &&
        props.paths.currPage !== "/TreatmentsPriorEpcoritamab" && props.paths.currPage !== "/TreatmentsAfterEpcoritamab" ) && (
      <a
        className="btn save-btn"
        onClick={HandleSubmitClick}
        style={{ fontWeight: "600", fontSize: "large" }}
      >
        Submit
      </a>
         )}
      {props.paths.nextPage && (
        <a onClick={HandleNextClick}>
          <img
            className="next-btn-padding"
            src="../Assets/images/next.png"
            alt=""
          />
        </a>
      )}
    </>
  );
};

export default SaveAndNavigate;