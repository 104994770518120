import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { DefaultAGGridProps, ErrorMessages, HTTPResponse, ToastMessageType } from "../../Helpers/Enums";
import { Abstractor_Msgs } from "../../Helpers/HelperText";
import { AbstractorService } from "../../WebApiServices/Abstractor.service";
import PatientDetails from "../../Components/PatientInformation";
import AbstractorModal from "./AbstractorModal";
import { PageAction } from "../../Helpers/Enums";
import ConfirmPopUp from "../../Components/ConfirmPopUp";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CenterService } from "../../WebApiServices/Center.service";
import { RemoveLocalStorageData } from "../../Helpers/Utilities";

export const ManageAbstractors = () => {
  const [abstractors, setAbstractors] = useState([]);
  const [filteredAbstractors, setFilteredAbstractors] = useState([]);

  const [showAbstractorPopUp, setShowAbstractorPopUp] = useState(false); 
  const [pageAction, setPageAction] = useState();
  const [loading, setLoading] = useState(true);
  const [abstractor, setAbstractor] = useState([]);
  const { ShowToast, ToggleLoader, HandleSessionTimeout, ShowActionPopUp } = useContext(AppContext);
  const [absIdToDelete, setAbsIdToDelete] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const searchRef = useRef("");

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const iconStyle = {
    width: 25,
    margin: 10,
    cursor: "pointer",
  };
  useEffect(() => {
    RemoveLocalStorageData("patientId");
    //RemoveLocalStorageData("uniqueEncryptedPatientId");
    //RemoveLocalStorageData("surveyDate");
    RemoveLocalStorageData("patient");
    const FetchData = async () => {
      ToggleLoader(true)
      await GetAllAbstractors();
      ToggleLoader(false)
    }
    FetchData();
  }, [])

  useEffect(() => {
    setFilteredAbstractors(abstractors?.filter((a) =>
      a.employeeName.replace(/\s/g, '').toLowerCase().includes(searchQuery.replace(/\s/g, '').toLowerCase())
    )
      .sort((a) => a.employeeName));
  }, [searchQuery, abstractors])

  const GetAllAbstractors = async () => {
    try {
      const response = await AbstractorService.GetAllAbstractors();

      if (response?.status !== HTTPResponse.OK) {
        if(response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
        }
        else
          throw response.error;
      }else{
        const { data } = response;
        setAbstractors(data);
      }
      setLoading(false);
    } catch {
      setLoading(false);
      ShowToast(ErrorMessages.LOAD_ERROR, ToastMessageType.Failed);
    }
  };



  const AddNew = () => {
    setShowAbstractorPopUp(true);
    setPageAction(PageAction.add);
    setAbstractor([]);
  };
  const Edit = (abstractor) => {
    setShowAbstractorPopUp(true);
    setPageAction(PageAction.edit);
    setAbstractor(abstractor);
  };
  const DeleteAbstractor = async (id, canDelete) => {
    debugger
    if (!canDelete) {
      const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
          "Alert",
          "This abstractor cannot be deleted as there are patients associated to it.",
          "Ok",
          true
        );
      };
      ShowAlertBox()
    } else {
    setShowDeleteConfirm(true);
    setAbsIdToDelete(id)
    }
  }
  const HandleDeleteAbstractor = async (id) => {
    try {
      ToggleLoader(true)
      const response = await AbstractorService.DeleteAbstractor(id);
      ToggleLoader(false)
      if (response.status === HTTPResponse.OK) {
        setFilteredAbstractors((prevState) => prevState?.filter(a => a.abstractorId !== id));
        setAbstractors((prevState) => prevState?.filter(a => a.abstractorId !== id));
        ShowToast(Abstractor_Msgs.DELETE_SUCCESS, ToastMessageType.Success);
      }
      else {
        if(response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
        else
          ShowToast(Abstractor_Msgs.DELETE_FAIL, ToastMessageType.Failed);
      }
    } catch {
      ShowToast(Abstractor_Msgs.DELETE_FAIL, ToastMessageType.Failed);
    }
  }
  const HandleSearch = () => {
    setSearchQuery(searchRef.current.value);
  }
  // GRID
  const columnDefs = [
    {
      headerName: "Abstractor ID",
      field: "abstractorId",
      width: 155,
      cellRenderer: (params) => (
        <div>
          {params.data.abstractorId}
        </div>
      )
    },
    {
      headerName: "Abstractor Name",
      field: "employeeName",
      // width: 100,
      cellRenderer: (params) => <div>{params.data.employeeName} </div>,
    },
    {
      headerName: "Email",
      field: "emailAddress",
      //minWidth: 150,
      flex: 2,
      cellRenderer: (params) => <div> {params.data.emailAddress} </div>,
    },
    {
      headerName: "Center",
      field: "centerName",
      //minWidth: 150,
      cellRenderer: (params) => <div> {params.data.centerName}</div>,
    },

    // EDIT canEdit
    {
      width: 100,
      cellRenderer: (params) => (
        <img
          src="../Assets/images/Icon-awesome-edit.png"
          alt="edit"
          style={iconStyle}
          onClick={() => Edit(params.data)}
          title="Edit Abstractor"
        />
      ),
      cellClass: "td",
    },

    // DELETE   canDelete
    {
      width: 100,
      cellRenderer: (params) => (
        // params.data.canDelete && 
        params.data.abstractorId !== 1 &&
        <img
          src="../Assets/images/Icon-material-delete.png"
          alt="delete"
          style={{ ...iconStyle, width: "17px" }}
          onClick={() => DeleteAbstractor(params.data.abstractorId, params.data.canDelete)}
          title="Delete Abstractor"
        />
      ),
      cellClass: "td",
      resizable: true,
    },
  ];
  const defaultColDef = {
    resizable: true,
    sortable: true,
    // flex: 1,
    cellClass: "td no-border",
    //headerClass: 'ag-cell-header'
  };
  // CSS class for highlighting edited and new rows
  const getRowClass = (params) => {
    return params.data.isEdited ? 'edited-row' : '';
  };
  const gridOptions = {
    columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
    pagination: true,
    paginationPageSize: DefaultAGGridProps.ROWS_PER_PAGE,
    getRowClass
  };
  const agGridLocaleText = {
    noRowsToShow: DefaultAGGridProps.NOT_FOUND,
  };
  const GetTableHeight = () => { 
      function GetHeight() {
          if (filteredAbstractors?.length < 4){
              return (50 * 4)
          }
          if (filteredAbstractors?.length < 11) {
              return (50 * filteredAbstractors?.length)
          }
          return 50 * 10;
      }

      return 100 + GetHeight();
    //return 100 + (filteredAbstractors?.length < 4
    //  ? 50 * 4
    //  : filteredAbstractors?.length < 11
    //    ? 50 * filteredAbstractors?.length
    //    : 50 * 10);
  };

  // CLOSE FORM
  const CloseModal = (abstractor) => {
    setShowAbstractorPopUp(false);

    if (abstractor.abstractorId !== undefined && abstractor.abstractorId !== 0) {
      let updatedList = [];
      if (abstractor.isNew) {
        abstractor.employeeName = abstractor.firstName + " " + abstractor.lastName;
        //reset isEdited of existing abstractors to false
        const abstractors1 = abstractors.map((abs) => ({
          ...abs,
          isEdited: false,
        }));

        updatedList = [...abstractors1, { ...abstractor, isEdited: true,canDelete: true,  }]
      }
      else
        updatedList = abstractors.map((row) => {

          if (row.abstractorId == abstractor.abstractorId) {
            row.firstName = abstractor.firstName;
            row.lastName = abstractor.lastName;
            row.employeeName = abstractor.firstName + " " + abstractor.lastName;
            row.emailAddress = abstractor.emailAddress;
            row.centerName = abstractor.centerName;
            return { ...row, isEdited: true };
          }
          return { ...row, isEdited: false };
        })
      setAbstractors(updatedList)
    }
  }
  //DELETE CONFIRM
  const YesClick = (abstractorId) => {
    HandleDeleteAbstractor(abstractorId)
    setShowDeleteConfirm(false)

  }
  const NoClick = () => {
    setShowDeleteConfirm(false)
  }

  return (
    <>
      <section className="patient-details-section">
        <PatientDetails />
      </section>

      <section className="main-common-page-section main-common-page-section-min-ht">
        <div className="container">
          {!loading &&
            <div className="row search-add-btn">
              <div className="col-6 col-sm-5 col-md-5 col-lg-4">
                <div className="searchBar">
                  <input
                    className="searchQueryInput-class"
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    placeholder="Abstractor Name"
                    ref={searchRef}
                    onChange={HandleSearch}
                  />
                  <button
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    <img
                      src="../Assets/images/Icon-feather-search.png"
                      width="20px"
                    />
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                <div className="">
                  <button
                    //data-bs-toggle="modal"
                    //data-bs-target="#AddAbstractorModal"
                    className="btn btn-outline-light btn-lg add-new-patient"
                    type="submit"
                    onClick={AddNew}
                  >
                    + Add New Abstractor
                  </button>
                </div>
              </div>
            </div>
          }
          <div className="user-tab">
            {loading ? (
              // <Loader/>
              <></>
            )
              :
              (<div
                className="ag-theme-alpine "
                style={{
                  height: filteredAbstractors?.length === 0 ? 322 : GetTableHeight(), //DefaultAGGridProps.TABLE_HEIGHT,
                  width: "100%",
                }}
              >
                <AgGridReact
                  rowData={filteredAbstractors}
                  gridOptions={{ ...gridOptions, localeText: agGridLocaleText }}
                  onPaginationChanged={() => {
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
              )
              //   <h2
              //   style={{
              //     height: 322,
              //     display: "flex",
              //     alignItems: "center",
              //     justifyContent: "center",
              //   }}
              // >
              // </h2>
            }
          </div>
        </div>
      </section>

      {showAbstractorPopUp && (
        <AbstractorModal
          pageAction={pageAction}
          abstractor={abstractor}
          CloseModal={CloseModal}
          GetAllAbstractors = {GetAllAbstractors}
        //centers={centers}
        //
        />
      )}
      {showDeleteConfirm && <ConfirmPopUp id={absIdToDelete} YesClick={YesClick} NoClick={NoClick} />}
    </>
  );
};

export default ManageAbstractors;