import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages, TLSMonitoringEnums } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import DateControl from "../../Components/DateControl";
import InfoPopUp from "../../Components/InfoPopUp";
import { CommonError_Msgs, RelevantBloodTests, TLSMonitoring_Msgs, TLS_Table } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { AppContext } from "../../Contexts/AppContextProvider";
import { TLSMonitoringService } from "../../WebApiServices/TLSMonitoring.service";
import { PatientService } from "../../WebApiServices/Patient.service";
import {
    GetLocalStorageData,
    GetLoggedInUserID,
    isStringEmpty,
    ParseDate,
    GetDifferenceInDays,
    IsDateBefore,
    IsDateBeforeMinus22Days,
    IsDateAfter,
    SubtractMonths,
    DateDisplayFormat,
    IsDateBetween,
    getOrdinalSuffix,
    GetOrdinal,
} from "../../Helpers/Utilities";
import { useParams } from "react-router-dom";

const TlsWeekMonitoring = () => {
    const { week } = useParams();
    const [navigatePaths, setNavPaths] = useState({
        prevPage: "",
        currPage: `/TlsWeekMonitoring/${week}`,
        nextPage: ""
    });
    let pid = GetLocalStorageData("patientId") ?? 0;
    const {
        ShowToast,
        ToggleLoader,
        HandleSessionTimeout,
        isSideMenuActive,
        setIsSideMenuActive,
        appState,
    } = useContext(AppContext);

    let tlsWeekTestCnt;
    let tlsWeekCnt;
    const [lastWeek, setLastWeek] = useState(false);

    const [showHideModalPopup, setShowHideModalPopup] = useState(false);
    const [showHideModalPopupLink, setShowHideModalPopupLink] = useState(false);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [q35ErrorMsg, setQ35ErrorMsg] = useState(null);
    const [q35OutOfRange, setQ35OutOfRange] = useState(TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg);


    const [radiographic_evaluationsRangeError_msg, setRadiographic_evaluationsRangeError_msg] = useState("");
    const [indexDate, setIndexDate] = useState('[Index Date]');
    const [index_dt_minus_2mo, setIndexDateMinus2Months] = useState('[index_date] - 2 months')
    const [venetoclaxStartDate, setVenetoclaxStartDate] = useState('[Venetoclax Start Date]');
    const [venetoclaxEndDate, setVenetoclaxEndDate] = useState('[Venetoclax End Date]');
    const [currentRampUpInitiationDate, setCurrentRampUpInitiationDate] = useState('[Ramp-Up Initiation Date]');
    const [nextRampUpInitiationDate, setNextRampUpInitiationDate] = useState('[Ramp-Up Initiation Date]');

    const BloodTestRow = {
        //Q35 Parent properties
        tlsmonitoringweekbridgeid: 0,
        tlsmonitoringid: 0,
        bloodtest_count_checked: null,
        bloodtest_count: "",
        bloodtest_count_unk: null,
        bloodTestCompleteEmpty: false,
        bloodTestCountEmpty: false,
        bloodTestOutOfRangeError: false,
    }
    const [bloodTestRowData, setBloodTestRowData] = useState([BloodTestRow]);

    const LoadData = async () => {
        try {
            ToggleLoader(true);
            if (pid > 0) {
                const response = await TLSMonitoringService.LoadTLSMonitoringWeekData(
                    pid, week
                );

                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;
                    setIndexDate(data.indexDate);
                    setVenetoclaxStartDate(data.venetoclaxStartDate || "[Venetoclax Start Date]");
                    setVenetoclaxEndDate(data.venetoclaxEndDate || "[Venetoclax End Date]");
                    setCurrentRampUpInitiationDate(data.currentRampUpInitiationDate || "[Ramp-Up Initiation Date]");
                    setNextRampUpInitiationDate(data.nextRampUpInitiationDate || "[Ramp-Up Initiation Date]");

                    const indexDtMinus2MoObj = SubtractMonths(data.indexDate, 2);
                    const indexDtMinus2Mo = DateDisplayFormat(indexDtMinus2MoObj);

                    setIndexDateMinus2Months(indexDtMinus2Mo || '[index_date] - 2 months');
                    if (
                        data.tlsBloodTestData != null &&
                        data.tlsBloodTestData.length > 0
                    ) {
                        const { tlsBloodTestData } = data;
                        const bloodTestData = mapTlsBloodTestDataToBloodTestRowDataLoad(tlsBloodTestData);
                        setBloodTestRowData(bloodTestData);
                    }
                    else {
                        const bloodTestData = mapTlsBloodTestDataToBloodTestRowDataLoad(bloodTestRowData);
                        setBloodTestRowData(bloodTestData);
                    }

                }
            }
            ToggleLoader(false);

        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };

    const mapTlsBloodTestDataToBloodTestRowDataLoad = (tlsBloodTestData) => {

        return tlsBloodTestData.map((data) => ({
            // Q35 Parent properties
            tlsmonitoringweekbridgeid: data.tlsmonitoringweekbridgeid,
            tlsmonitoringid: data.tlsmonitoringid,
            bloodtest_count_checked: data.bloodtestCount != null ? 1 : null,
            bloodtest_count: data.bloodtestCount,
            bloodtest_count_unk: data.bloodtestCountUnk ? 99 : null,
            bloodTestCompleteEmpty: false,
            bloodTestCountEmpty: false,
            bloodTestOutOfRangeError: false,
        }));
    };

    useEffect(() => {
        async function FetchData() {
            pid = GetLocalStorageData("patientId");
            
            if (pid > 0 && parseInt(week) !== 0) {
                const response2 = await PatientService.GetPatientConfigData(pid);
                if (response2?.status === HTTPResponse.OK && response2?.data) {                 
                    const { data } = response2;
                    const { item4, item3 } = data;

                    if (item4?.length > 0) {
                        const labTests = item4
                            .filter(x => x.weeknum === Number(week))
                            .map(x => (Array.isArray(x.tests) ? x.tests.length : 0))[0] || 0;

                        // Set the state for lab test count
                        tlsWeekTestCnt = labTests;
                        tlsWeekCnt = appState?.tlsWeekCnt;
                    }   
                    else if (item3?.length > 0) {
                        const labTests = item3
                            .filter(x => x.weeknum === Number(week))
                            .map(x => (Array.isArray(x.tests) ? x.tests.length : 0))[0] || 0;

                        // Set the state for lab test count
                        tlsWeekTestCnt = labTests;
                        tlsWeekCnt = appState?.tlsWeekCnt === null ? item3.length : appState?.tlsWeekCnt;
                    }
                }
            }
            
            const prevWk = Number(week) - 1;
            const nextWk = Number(week) + 1;
            const isFirstWk = Number(week) == 1;
            
            const isLastWk = tlsWeekCnt == Number(week);
            // alert(isLastWk)
            setLastWeek(isLastWk)            
            let newPaths = { ...navigatePaths };
            
            let currentTestId = 1;
            newPaths.prevPage = isFirstWk ? "/TlsMonitoring" : `/TlsWeekMonitoring/${prevWk}`;
            newPaths.nextPage = isLastWk && tlsWeekTestCnt === 0 ? "/TreatmentInterruptions" : Number(tlsWeekTestCnt) > 0 ? `/TlsLabTestMonitoring/${Number(week)}/${currentTestId}` : `/TlsWeekMonitoring/${nextWk}`;

            setNavPaths(newPaths);
            pid = GetLocalStorageData("patientId");
            if (pid > 0 && week != 0) {
                await LoadData();
            }
        }
        FetchData();
    }, [week]);



    async function ValidateRepeaterData(repeaterarray, index) {
        let result = true;

        //Q35
        if (repeaterarray.bloodtest_count_checked === null && repeaterarray.bloodtest_count_unk === null) {
            result = false;
            repeaterarray.bloodTestCompleteEmpty = true;
        } else {
            repeaterarray.bloodTestCompleteEmpty = false;
        }

        //Q35
        if (repeaterarray.bloodtest_count_checked === 1 && repeaterarray.bloodtest_count_unk === null && (repeaterarray.bloodtest_count === null || repeaterarray.bloodtest_count === "")) {
            result = false;
            repeaterarray.bloodTestCountEmpty = true;
        } else {
            repeaterarray.bloodTestCountEmpty = false;
        }

        return result;
    }

    function formatWholeNumbers(e) {
        // Allow backspace, arrow keys, and delete
        if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(e.key)) {
            return;
        }
        // Prevent non-numeric characters
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
            return;
        }
        const value = e.target.value;
        const selectionStart = e.target.selectionStart;
        const newValue = value.slice(0, selectionStart) + e.key + value.slice(e.target.selectionEnd);
        // Prevent multiple '.'
        if (e.key === '.' && value.includes('.')) {
            e.preventDefault();
            return;
        }
        // Prevent '.' as the first character
        if (e.key === '.' && value === '') {
            e.preventDefault();
            return;
        }
        // Allow only one digit after decimal point
        if (newValue.includes('.') && newValue.split('.')[1].length > 2) {
            e.preventDefault();
            return;
        }
    }

    const HandleQ36RepeaterFieldChange = (e, parentIdx, childIdx) => {
        const { name, id, value } = e.target;
        let updatedArray = [...bloodTestRowData];

        switch (name) {
            //Radio Q35 
            case "bloodTestCompletedCount_checked_" + parentIdx: {
                updatedArray[parentIdx].bloodtest_count_checked = 1;
                updatedArray[parentIdx].bloodtest_count_unk = null;
                updatedArray[parentIdx].bloodTestCompleteEmpty = false;
                updatedArray[parentIdx].bloodTestCountEmpty = false;
                updatedArray[parentIdx].bloodTestOutOfRangeError = false;
            }
                break;
            case "bloodTestCompletedCount_" + parentIdx: {
                updatedArray[parentIdx].bloodtest_count = value;
                updatedArray[parentIdx].bloodtest_count_checked = 1;
                updatedArray[parentIdx].bloodtest_count_unk = null;
                updatedArray[parentIdx].bloodTestCompleteEmpty = false;
                updatedArray[parentIdx].bloodTestCountEmpty = false;
                tlsWeekTestCnt = value;
                if (parseInt(value) < 1 || parseInt(value) > 6) {
                    let resultRange = TLSMonitoring_Msgs.TestCompleteTxtEmptyMsg.replace('[value]', value);
                    resultRange = resultRange.replace('[value units]', '');
                    setQ35OutOfRange(resultRange);
                    updatedArray[parentIdx].bloodTestOutOfRangeError = true;
                }
                else {
                    updatedArray[parentIdx].bloodTestOutOfRangeError = false;
                }


            }
                break;
            case "bloodTestCompletedCount_Unk_" + parentIdx: {
                updatedArray[parentIdx].bloodtest_count_unk = 99;
                updatedArray[parentIdx].bloodtest_count_checked = null;
                updatedArray[parentIdx].bloodtest_count = "";
                updatedArray[parentIdx].bloodTestCompleteEmpty = false;
                updatedArray[parentIdx].bloodTestCountEmpty = false;
                updatedArray[parentIdx].bloodTestOutOfRangeError = false;
                updatedArray[parentIdx].bloodTestDetails = [];
                tlsWeekTestCnt = 0;
            }
                break;
            default:
                break;
        }
        setBloodTestRowData([...updatedArray]);

        const prevWk = Number(week) - 1;
        const nextWk = Number(week) + 1;
        const isFirstWk = Number(week) == 1;
        tlsWeekCnt = appState?.tlsWeekCnt;
        const isLastWk = tlsWeekCnt <= Number(week);
        
        let newPaths = { ...navigatePaths };
        let currentTestId = 1;
        newPaths.prevPage = isFirstWk ? "/TlsMonitoring" : `/TlsWeekMonitoring/${prevWk}`;
        newPaths.nextPage = isLastWk && tlsWeekTestCnt === 0 ? "/TreatmentInterruptions" : Number(tlsWeekTestCnt) > 0 ? `/TlsLabTestMonitoring/${Number(week)}/${currentTestId}` : `/TlsWeekMonitoring/${nextWk}`;

        setNavPaths(newPaths);
    }


    async function ValidateQ35Q36Repeater(list) {
        // Your asynchronous processing logic for the list goes here
        const promises = list.map((item, index) => ValidateList(item, index));
        const results = await Promise.all(promises);
        return !results.includes(false);
    }

    async function ValidateList(item, index) {
        // Your asynchronous processing logic for an individual item
        return new Promise((resolve) => {
            setTimeout(() => {
                const isValid = ValidateRepeaterData(item, index);
                resolve(isValid);
            }, 1000); // Simulate async work
        });
    }


    const mapBloodTestRowDataToTlsBloodTestModelSave = (bloodTestRowData) => {

        return bloodTestRowData.map((row) => ({
            // Q35 Parent properties
            Tlsmonitoringweekbridgeid: row.tlsmonitoringweekbridgeid,
            tlsmonitoringid: row.tlsmonitoringid,
            BloodtestCountChecked: row.bloodtest_count_checked,
            BloodtestCount: row.bloodtest_count,
            BloodtestCountUnk: row.bloodtest_count_unk,
            BloodTestCompleteEmpty: false,
            BloodTestCountEmpty: false,
            BloodTestOutOfRangeError: false,
        }));
    };

    const SavePage = async (validate) => {
        try {
            let isValid = true;

            isValid = await ValidateQ35Q36Repeater(bloodTestRowData);

            if (isValid) {
                ToggleLoader(true);
                setShowTopErrMsg(false)

                const bloodTestRowDataToSave = mapBloodTestRowDataToTlsBloodTestModelSave(bloodTestRowData);

                // Tlsbloodtestdetails: [],

                const tlsMonitoringModel = {
                    patientId: pid,
                    CurrentWeek: week,
                    IsValidated: validate,
                    LoggedInUserId: GetLoggedInUserID(),
                    TlsBloodTestData: bloodTestRowDataToSave,
                };
                if (pid > 0) {
                    const response = await TLSMonitoringService.SaveTLSMonitoringWeekDetails(
                        tlsMonitoringModel
                    );
                    ToggleLoader(false);
                    if (response.status !== HTTPResponse.OK) {
                        if (response?.status === HTTPResponse.Unauthorized) {
                            HandleSessionTimeout();
                        } else throw response.error;
                    } else {
                        await LoadData();
                        ToggleLoader(false);
                        ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                    }
                }
                return true;
            }
            else {
                ToggleLoader(false);
                setShowTopErrMsg(true)
                return false;
            }
        } catch (error) {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };

    return (
        <>
            {/*  */}
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>

            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.TlsMonitoringWeek.toString() + week.toString()} />

                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>WEEK {week}</h4>
                            </div>


                            {showTopErrMsg && (
                                <div className="clean-error ">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}


                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div className="question-answer">

                                        <div className="question-bot-sapce single-dig-quest">
                                            {/* Loop 2 Start */}


                                            {bloodTestRowData &&
                                                bloodTestRowData.length > 0 &&
                                                // bloodTestRowData.some(item => item.tlsmonitoringweekbridgeid > 0) && 
                                                bloodTestRowData?.map((itemParent, itemParentidx) => (
                                                    <div className="dependent-section">
                                                        <div>
                                                            <div className="question question-weight">
                                                                <span>35.</span>
                                                                
                                                                
                                                                {
                                                                    !lastWeek ? (
                                                                    (currentRampUpInitiationDate === "[Ramp-Up Initiation Date]" 
                                                                || nextRampUpInitiationDate === "[Ramp-Up Initiation Date]") ? (
                                                                
                                                                    <span className="quest-text-pad">
                                                                    Please indicate the total number of blood tests
                                                                    where at least one of the
                                                                    {" "}
                                                                    <span
                                                                        data-tooltip-id="encounterTip"
                                                                        className="modal-popup"
                                                                        onClick={() =>
                                                                            setShowHideModalPopup(true)
                                                                        }
                                                                    >
                                                                        following tests
                                                                    </span>
                                                                    {" "}
                                                                    were performed on or after the {getOrdinalSuffix(parseInt(week))}{" "} week of ramp-up
                                                                    but before the 
                                                                    {" "}
                                                                    

                                                                    {" "} 
                                                                    
                                                                    {nextRampUpInitiationDate === "[Ramp-Up Initiation Date]"
                                                                        ? (
                                                                        <>end of ramp-up on <span style={{ color: PageDesignConstants.fontColor }}>{venetoclaxEndDate}</span>.</>
                                                                        )
                                                                        : (
                                                                        <>
                                                                            {!lastWeek ? getOrdinalSuffix(parseInt(week) + 1) : ""}
                                                                            week of ramp-up initiated on{" "}
                                                                            <span style={{ color: PageDesignConstants.fontColor }}>{nextRampUpInitiationDate}</span>.
                                                                        </>
                                                                        )
                                                                    }
                                                                        
                                                                    
                                                                        
                                                                    </span>
                                                                    )
                                                                    :(
                                                                    
                                                                    <span className="quest-text-pad">
                                                                    Please indicate the total number of blood tests
                                                                    completed where at least one of the
                                                                    {" "}
                                                                    <span
                                                                        data-tooltip-id="encounterTip"
                                                                        className="modal-popup"
                                                                        onClick={() =>
                                                                            setShowHideModalPopup(true)
                                                                        }
                                                                    >
                                                                        following tests
                                                                    </span>
                                                                    {" "}
                                                                    were performed on or after the {getOrdinalSuffix(parseInt(week))}{" "} week of ramp-up
                                                                    initiated on 
                                                                    
                                                                    {" "}
                                                                    <span style={{ color: PageDesignConstants.fontColor }}>
                                                                    {currentRampUpInitiationDate}
                                                                    </span>
                                                                    {" "}

                                                                    but before the 
                                                                    {" "}
                                                                    

                                                                    {" "} 
                                                                    
                                                                    {nextRampUpInitiationDate === "[Ramp-Up Initiation Date]"
                                                                        ? (
                                                                        <>end of ramp-up on <span style={{ color: PageDesignConstants.fontColor }}>{venetoclaxEndDate}</span>.</>
                                                                        )
                                                                        : (
                                                                        <>
                                                                            {!lastWeek ? getOrdinalSuffix(parseInt(week) + 1) : ""}
                                                                            week of ramp-up initiated on{" "}
                                                                            <span style={{ color: PageDesignConstants.fontColor }}>{nextRampUpInitiationDate}</span>.
                                                                        </>
                                                                        )
                                                                    }
                                                                    
                                                                    </span>
                                                                )
                                                            ):(
                                                                (currentRampUpInitiationDate === "[Ramp-Up Initiation Date]") ?  
                                                                (
                                                                <span className="quest-text-pad">
                                                                Please indicate the total number of blood tests
                                                                where at least one of the
                                                                {" "}
                                                                <span
                                                                    data-tooltip-id="encounterTip"
                                                                    className="modal-popup"
                                                                    onClick={() =>
                                                                        setShowHideModalPopup(true)
                                                                    }
                                                                >
                                                                    following tests
                                                                </span>
                                                                {" "}
                                                                were performed on or after the {getOrdinalSuffix(parseInt(week))}{" "} week of ramp-up
                                                                but before the end of ramp-up on{" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{venetoclaxEndDate}</span>.
                                                                </span>
                                                                ):
                                                                (
                                                                    <span className="quest-text-pad">
                                                                Please indicate the total number of blood tests
                                                                where at least one of the
                                                                {" "}
                                                                <span
                                                                    data-tooltip-id="encounterTip"
                                                                    className="modal-popup"
                                                                    onClick={() =>
                                                                        setShowHideModalPopup(true)
                                                                    }
                                                                >
                                                                    following tests
                                                                </span>
                                                                {" "}
                                                                were performed on or after the {getOrdinalSuffix(parseInt(week))}{" "} week of ramp-up
                                                                initiated on 
                                                                {" "}
                                                                    <span style={{ color: PageDesignConstants.fontColor }}>
                                                                    {currentRampUpInitiationDate}
                                                                    </span>
                                                                {" "}
                                                                but before the end of ramp-up on{" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{venetoclaxEndDate}</span>.
                                                                </span>

                                                                )

                                                            )
                                                            }


                                                            </div>
                                                            <div className="answer-pad-left">
                                                                <div className="option-without-input">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={"bloodTestCompletedCount_checked_" + itemParentidx}
                                                                            name={"bloodTestCompletedCount_checked_" + itemParentidx}
                                                                            value={1}
                                                                            checked={
                                                                                itemParent.bloodtest_count_checked === 1
                                                                            }
                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, null)}
                                                                        />
                                                                        <input
                                                                            className="input-dash input_custome-space_sm"
                                                                            name={"bloodTestCompletedCount_" + itemParentidx}
                                                                            type="text"
                                                                            maxLength={2}
                                                                            value={itemParent.bloodtest_count}
                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, null)}
                                                                            onKeyDown={formatWholeNumbers}
                                                                        />
                                                                        <span className="radio-text-padding">
                                                                            blood tests
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="option-without-input">
                                                                    <label className="option-fbox custom-spacing-lbl">
                                                                        <input
                                                                            type="radio"
                                                                            id={"bloodTestCompletedCount_Unk_" + itemParentidx}
                                                                            name={"bloodTestCompletedCount_Unk_" + itemParentidx}
                                                                            checked={
                                                                                itemParent.bloodtest_count_unk === 99
                                                                            }
                                                                            value={99}
                                                                            onChange={(e) => HandleQ36RepeaterFieldChange(e, itemParentidx, null)}
                                                                        />
                                                                        {/* <label> */}
                                                                        <span htmlFor="total_No_interruptions_99">
                                                                            Unknown
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <ErrorField
                                                                    show={itemParent.bloodTestCompleteEmpty}
                                                                    message={TLSMonitoring_Msgs.Total_No_interruptionsEmpty}
                                                                />
                                                                <ErrorField
                                                                    show={itemParent.bloodTestCountEmpty}
                                                                    message={TLSMonitoring_Msgs.TextEmpty}
                                                                />
                                                                <ErrorField
                                                                    show={itemParent.bloodTestOutOfRangeError}
                                                                    message={q35OutOfRange}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                {/**/}
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showHideModalPopup && (
                <InfoPopUp
                    description={RelevantBloodTests}
                    OkClick={() => setShowHideModalPopup(false)}
                />
            )}
            {showHideModalPopupLink && (
                <InfoPopUp
                    description={TLS_Table}
                    OkClick={() => setShowHideModalPopupLink(false)}
                />
            )}
        </>
    );
};

export default TlsWeekMonitoring;

