import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessages, ToastMessageType } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import VenetoclaxDosingPopUp from "../../Components/VenetoclaxDosingPopUp";
import {
  AllowOnlyIntegers,
  GetLocalStorageData,
  GetLoggedInUserID,
  ValueInRange
} from "../../Helpers/Utilities";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs, VenRampUp_Msgs } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { VenetoclaxRampUpDataService } from "../../WebApiServices/VenetoclaxRampUp.service";

let pid = 0;
const VenetoclaxRampUp = () => {
  const {
    ShowToast,
    ToggleLoader,
    HandleSessionTimeout,
    appState
  } = useContext(AppContext);

  const navigatePaths = {
    prevPage: "/ClinicalCharacteristics",
    currPage: "/VenetoclaxRamUp",
    nextPage: appState?.venRampUpWeekCnt ?? 0 > 0 ? "/VenetoclaxRampUpWeek/1" : "/TlsMonitoring"
  };

  const [showHidePopUp, setShowHidePopUp] = useState(false);
  const [initDate, setInitDate] = useState("[insert date of initiation of venetoclax]");

  //ST 24/09/2024 VenetoclaxRamUp data
  const [venetoclaxRamUp, setVenetoclaxRamUp] = useState({
    //23
    veneto_RampUp_Dose: null,
    //24
    rb_weeks: null,
    txt_weeks: "",
    //25
    rbl_uric_acid_Agent: null,
    chk_uric_acid_Agent_Allopurinol: false,
    chk_uric_acid_Agent_Uloric: false,
    chk_uric_acid_Agent_Other: false,
    txt_uric_acid_Agent_Other_txt: "",
  });

  //ST 24/09/2024 Show/Hide hard error message
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  //ST 24/09/2024 Hard error message List
  const InitialHardErrList = {
    //23
    veneto_RampUp_DoseEmpty: false,
    //24
    weeksEmpty: false,
    weeksNotInRangeErr: false,//soft err
    //25
    uric_acid_AgentEmpty: false,
    uric_acid_Agent_Other_txtEmpty: false,
  };

  const [hardErrList, setHardErrList] = useState(InitialHardErrList);

  useEffect(() => {
    async function FetchData() {
      pid = GetLocalStorageData("patientId");
      if (pid > 0) {
        await LoadData();
      }
    }
    FetchData();
  }, []);

  async function LoadData() {
    try {
      const response = await VenetoclaxRampUpDataService.GetVenetoclaxRampUpData(pid);
      if (response?.status !== HTTPResponse.OK) {
        if (response?.status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else if (response?.status === HTTPResponse.BadRequest)
          throw response.error;
      } else {
        if (response != null) {
          const { data } = response;

          const initDate = data.inititaionDate;
          if (initDate != "") {
            setInitDate(initDate);
          }
          setVenetoclaxRamUp(prev => ({
            ...prev,
            veneto_RampUp_Dose: data.rampupDosing,
            rb_weeks: data.weeks != null,
            txt_weeks: data.weeks ?? "",
            rbl_uric_acid_Agent: data.uricNone ? 1 : data.uricUnk ? 99 : null,
            chk_uric_acid_Agent_Allopurinol: data.uricAllopurinol,
            chk_uric_acid_Agent_Uloric: data.uricUloric,
            chk_uric_acid_Agent_Other: data.uricOth,
            txt_uric_acid_Agent_Other_txt: data.uricOthTxt,
          }))
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  //ST 23/09/24 Save data in DB
  const SavePage = async (validate) => {
    try {
      setHardErrList(InitialHardErrList);

      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false);
        let payload = {
          patientId: pid,
          loggedInUserID: GetLoggedInUserID(),
          IsValidated: validate,

          RampupDosing: venetoclaxRamUp.veneto_RampUp_Dose,
          Weeks: venetoclaxRamUp.txt_weeks,
          UricNone: venetoclaxRamUp.rbl_uric_acid_Agent === 1,
          UricAllopurinol: venetoclaxRamUp.chk_uric_acid_Agent_Allopurinol,
          UricUloric: venetoclaxRamUp.chk_uric_acid_Agent_Uloric,
          UricOth: venetoclaxRamUp.chk_uric_acid_Agent_Other,
          UricUnk: venetoclaxRamUp.rbl_uric_acid_Agent === 99,
          UricOthTxt: venetoclaxRamUp.txt_uric_acid_Agent_Other_txt,
        };
        if (pid > 0) {
          ToggleLoader(true);
          const response = await VenetoclaxRampUpDataService.SaveVenetoclaxRampUpData(payload);
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            }
            else
              throw response.error;
          }
          else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData();

            if (Number(venetoclaxRamUp.txt_weeks) > 0) {
              navigatePaths.nextPage = "/VenetoclaxRampUpWeek/1";
            }
            else {
              navigatePaths.nextPage = "/TlsMonitoring";
            }
          }
        }
        return true;
      } else {
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  //ST 23/09/2024 Validate the selections done before saving the data
  const ValidateForm = () => {
    let result = true;
    const {
      veneto_RampUp_Dose,
      txt_weeks, rbl_uric_acid_Agent,
      chk_uric_acid_Agent_Allopurinol,
      chk_uric_acid_Agent_Uloric,
      chk_uric_acid_Agent_Other,
      txt_uric_acid_Agent_Other_txt
    } = venetoclaxRamUp;

    const veneto_RampUp_DoseEmpty = veneto_RampUp_Dose == null;
    const weeksEmpty = txt_weeks === "";
    const uric_acid_AgentEmpty = rbl_uric_acid_Agent == null && !chk_uric_acid_Agent_Allopurinol && !chk_uric_acid_Agent_Uloric && !chk_uric_acid_Agent_Other;
    const uric_acid_Agent_Other_txtEmpty = chk_uric_acid_Agent_Other && txt_uric_acid_Agent_Other_txt == "";

    setHardErrList(prev => ({
      ...prev,
      veneto_RampUp_DoseEmpty,
      weeksEmpty,
      uric_acid_AgentEmpty,
      uric_acid_Agent_Other_txtEmpty
    }))
    if (veneto_RampUp_DoseEmpty || weeksEmpty || uric_acid_AgentEmpty || uric_acid_Agent_Other_txtEmpty) {
      result = false;
    }
    return result;
  };

  const HandleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "veneto_RampUp_Dose":
        {
          const isYes = Number(value) === 1;
          setVenetoclaxRamUp(prev => ({
            ...prev,
            veneto_RampUp_Dose: Number(value),
            rb_weeks: isYes,
            txt_weeks: isYes ? 5 : ""
          }))
          setHardErrList(prev => ({
            ...prev,
            veneto_RampUp_DoseEmpty: false,
            weeksEmpty: isYes ? false : prev.weeksEmpty,
            weeksNotInRangeErr: false
          }))
        }
        break;
      case "rb_weeks":
        {
          setVenetoclaxRamUp(prev => ({
            ...prev,
            rb_weeks: true
          }))
        }
        break;
      case "txt_weeks":
        {
          if (value !== "" && !AllowOnlyIntegers(value)) {
            break;
          }
          //const weeks = Number(value) || "";
          setVenetoclaxRamUp(prev => ({
            ...prev,
            rb_weeks: true,
            txt_weeks: value
          }))
          setHardErrList(prev => ({
            ...prev,
            weeksEmpty: false,
            weeksNotInRangeErr: value != "" && !ValueInRange(Number(value), 5, 10)
          }))
        }
        break;
      case "rbl_uric_acid_Agent":
        {
          setVenetoclaxRamUp(prev => ({
            ...prev,
            rbl_uric_acid_Agent: Number(value),
            chk_uric_acid_Agent_Allopurinol: false,
            chk_uric_acid_Agent_Uloric: false,
            chk_uric_acid_Agent_Other: false,
            txt_uric_acid_Agent_Other_txt: ""
          }))
          setHardErrList(prev => ({
            ...prev,
            uric_acid_AgentEmpty: false,
            uric_acid_Agent_Other_txtEmpty: false,
          }))
        }
        break;
      case "chk_uric_acid_Agent_Allopurinol":
      case "chk_uric_acid_Agent_Uloric":
      case "chk_uric_acid_Agent_Other":
        {
          setVenetoclaxRamUp(prev => ({
            ...prev,
            [name]: !prev[name],
            rbl_uric_acid_Agent: null,
            txt_uric_acid_Agent_Other_txt: name === "chk_uric_acid_Agent_Other" ? "" : prev.txt_uric_acid_Agent_Other_txt
          }))
          setHardErrList(prev => ({
            ...prev,
            uric_acid_AgentEmpty: false,
            uric_acid_Agent_Other_txtEmpty: name === "chk_uric_acid_Agent_Other" ? false : prev.uric_acid_Agent_Other_txtEmpty,
          }))
        }
        break;
      case "txt_uric_acid_Agent_Other_txt":
        {
          setVenetoclaxRamUp(prev => ({
            ...prev,
            [name]: value,
            rbl_uric_acid_Agent: null,
            chk_uric_acid_Agent_Other: true,
          }))
          setHardErrList(prev => ({
            ...prev,
            uric_acid_AgentEmpty: false,
            uric_acid_Agent_Other_txtEmpty: false
          }))
        }
        break;

      default: break
    }
  };

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.VenetoclaxRampup} />

          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>VENETOCLAX RAMP-UP CHARACTERISTICS</h4>
              </div>
              {showTopErrMsg && <div className="clean-error ">
                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
              </div>
              }
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="question-bot-sapce single-dig-quest">
                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>23.</span>
                          <span className="quest-text-pad">
                            Was the venetoclax ramp-up{" "}
                            <span
                              className="modal-popup"
                              data-tooltip-id="encounterTip"
                              onClick={() => setShowHidePopUp(true)}
                            >
                              dosing per-label
                            </span>
                            ? &nbsp;
                            <i>Please note that if the venetoclax ramp-up dosing schedule is unknown, 
                              this patient may not be eligible for the study. Please return to the 
                              eligibility section and review your responses.
                            </i>     
                            </span>
                        </div>
                        <div className="double-dig-answer">
                          <div className="option-without-input">
                            <input
                              type="radio"
                              id="veneto_RampUp_Dose_1"
                              name="veneto_RampUp_Dose"
                              checked={venetoclaxRamUp.veneto_RampUp_Dose === 1}
                              value={1}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="veneto_RampUp_Dose_1">
                              <span className="radio-text-padding">Yes</span>
                            </label>
                          </div>

                          <div className="option-without-input">
                            <input
                              type="radio"
                              id="veneto_RampUp_Dose_2"
                              name="veneto_RampUp_Dose"
                              checked={venetoclaxRamUp.veneto_RampUp_Dose === 2}
                              value={2}
                              onChange={HandleFieldChange}
                            />
                            <label htmlFor="veneto_RampUp_Dose_2">
                              <span className="radio-text-padding">No</span>
                            </label>
                          </div>
                        </div>
                        <ErrorField show={hardErrList.veneto_RampUp_DoseEmpty} message={VenRampUp_Msgs.doseEmpty} />
                      </div>

                      <div className="question-bot-sapce">
                        {venetoclaxRamUp.veneto_RampUp_Dose === 1 &&
                          <i>
                            Please note that this response has been pre-populated with 5 weeks as you indicated in the previous
                            question that this patient received a venetoclax ramp-up dosing schedule as per label.
                            If this response is inconsistent with the patient chart, please update the response above.
                          </i>
                        }
                        <div className="question question-weight">
                          <span>24.</span>
                          <span className="quest-text-pad">
                            Please enter total number of weeks of venetoclax
                            ramp-up phase: (weeks).
                          </span>
                        </div>
                        <div className="double-dig-answer">
                          <div className="option-without-input">
                            <label>
                              <input
                                type="radio"
                                id="rb_weeks"
                                name="rb_weeks"
                                checked={venetoclaxRamUp.rb_weeks}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Venetoclax ramp-up:{" "}
                              </span>
                              <input
                                className="input-dash input_custome-space_sm"
                                name="txt_weeks"
                                type="text"
                                maxLength={2}
                                value={venetoclaxRamUp.txt_weeks}
                                onChange={HandleFieldChange}
                                disabled={venetoclaxRamUp.veneto_RampUp_Dose === 1}
                              />
                              <span className="radio-text-padding">
                                (weeks){" "}
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorField show={hardErrList.weeksEmpty} message={VenRampUp_Msgs.weeksEmpty} />
                        <ErrorField show={hardErrList.weeksNotInRangeErr} message={CommonError_Msgs.NotInRange.replace('[value]', venetoclaxRamUp.txt_weeks).replace('[value units]', 'weeks')} />
                      </div>

                      <div className="question-bot-sapce">
                        <div className="question question-weight">
                          <span>25.</span>
                          <span className="quest-text-pad">
                            Did the patient take any uric acid reduction agents
                            prior to starting venetoclax ramp-up on{" "}
                            <span style={{ color: PageDesignConstants.fontColor }}>
                              {initDate}
                            </span>?
                          </span>
                        </div>
                        <div className="double-dig-answer">
                          <span className="question-weight">
                            Agent
                          </span>
                          {/* <div className="double-dig-answer"> */}
                          <div className="option-without-input">
                            <label>
                              <input
                                type="radio"
                                id="rbl_uric_acid_Agent_1"
                                name="rbl_uric_acid_Agent"
                                value={1}
                                checked={venetoclaxRamUp.rbl_uric_acid_Agent === 1}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">None</span>
                            </label>
                          </div>
                          <div className="option-without-input">
                            <label>
                              <input
                                type="checkbox"
                                id="chk_uric_acid_Agent_Allopurinol"
                                name="chk_uric_acid_Agent_Allopurinol"
                                checked={venetoclaxRamUp.chk_uric_acid_Agent_Allopurinol}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Allopurinol
                              </span>
                            </label>
                          </div>
                          <div className="option-without-input">
                            <label>
                              <input
                                type="checkbox"
                                id="chk_uric_acid_Agent_Uloric"
                                name="chk_uric_acid_Agent_Uloric"
                                checked={venetoclaxRamUp.chk_uric_acid_Agent_Uloric}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Uloric
                              </span>
                            </label>
                          </div>
                          <div className="option-without-input">
                            <label>
                              <input
                                type="checkbox"
                                id="chk_uric_acid_Agent_Other"
                                name="chk_uric_acid_Agent_Other"
                                checked={venetoclaxRamUp.chk_uric_acid_Agent_Other}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Other, specify
                                <input
                                  className="input-dash input_custome-space"
                                  name="txt_uric_acid_Agent_Other_txt"
                                  type="text"
                                  maxLength={500}
                                  value={venetoclaxRamUp.txt_uric_acid_Agent_Other_txt}
                                  onChange={HandleFieldChange}
                                />
                              </span>
                            </label>
                          </div>

                          <div className="option-without-input">
                            <label>
                              <input
                                type="radio"
                                id="rbl_uric_acid_Agent_99"
                                name="rbl_uric_acid_Agent"
                                value={99}
                                checked={venetoclaxRamUp.rbl_uric_acid_Agent === 99}
                                onChange={HandleFieldChange}
                              />
                              <span className="radio-text-padding">
                                Unknown
                              </span>
                            </label>
                          </div>
                          {/* </div> */}
                        </div>
                        <ErrorField show={hardErrList.uric_acid_AgentEmpty} message={VenRampUp_Msgs.agentEmpty} />
                        <ErrorField show={hardErrList.uric_acid_Agent_Other_txtEmpty} message={VenRampUp_Msgs.agentTxtEmpty} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showHidePopUp && (
        <VenetoclaxDosingPopUp OkClick={() => setShowHidePopUp(false)} />
      )}
    </>
  );
};

export default VenetoclaxRampUp;
